<template>
  <div>
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        variant="gradient-primary"
        @click="submit"
      >
        <i
          class="fad fa-cloud-download-alt"
          style="color: #fff"
        />
        บันทึกข้อมูล
      </b-button>
    </div>
    <b-row class="match-height mt-1">
      <b-col md="7">
        <b-card>
          <h3>
            <i class="fad fa-university" /> จัดการเลขบัญชีธนาคารลูกค้าโอนเข้า
          </h3>
          <b-tabs pills>
            <b-tab
              title="SCB (ไทยพาณิชย์) 1"
              active
            >
              <b-form-group
                label="เปิด / ปิด บัญชี"
                label-for="customDelimiter"
              >
                <b-form-checkbox
                  v-model="agent.checkbank1"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  value="1"
                  unchecked-value="0"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <v-select
                  v-model="agent.dip_bank_code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="banklist"
                  :reduce="name => name.bankcode"
                />
              </b-form-group>
              <b-form-group
                label="เลขที่บัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="agent.dip_bank_accno"
                  placeholder="เลขที่บัญชี"
                />
              </b-form-group>

              <b-form-group
                label="ชื่อบัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.dip_bank_accname"
                  placeholder="ชื่อบัญชี"
                />
              </b-form-group>
              <b-form-group
                label="Prefix"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.prefix"
                  placeholder="Prefix"
                />
              </b-form-group>
              <b-form-group
                label="เว็บไซต์"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.site"
                  placeholder="เว็บไซต์"
                />
              </b-form-group>
              <b-form-group
                label="ชื่อเว็บไซต์"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.site_name"
                  placeholder="ชื่อเว็บไซต์"
                />
              </b-form-group>
              <hr>
              <div class="card-credit">
                <h4>
                  โยกเงินอัตโนมัติ SCB 1
                </h4>
                <b-form-group
                  label="เปิด / ปิด โยกอัตโนมัติ"
                  label-for="customDelimiter"
                >
                  <b-form-checkbox
                    v-model="movemoney.move_dep1_status"
                    class="custom-control-success"
                    name="check-button"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="จำนวนเงินที่ถอน"
                  label-for="vi-first-name"
                >
                  <b-form-input
                    id="vi-first-name"
                    v-model="movemoney.move_dep1_amount"
                    placeholder="0"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="web">ถอนไปยังธนาคาร</label>
                  <v-select
                    v-model="movemoney.move_dep1_bank"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                    class="bg-white rounded"
                    placeholder="เลือกธนาคาร"
                  />
                </b-form-group>
                <b-form-group
                  label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                  label-for="vi-first-acc"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="movemoney.move_dep1_acc"
                      placeholder="ระบุเลขที่บัญชี"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="checkAcc(1)"
                      >
                        ค้นหา
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="px-4 btn-icon"
                  @click="confirm(1)"
                >
                  ยืนยันข้อมูล
                </b-button>
              </div>
            </b-tab>
            <b-tab
              title="SCB (ไทยพาณิชย์) 2"
            >
              <b-form-group
                label="เปิด / ปิด บัญชี"
                label-for="customDelimiter"
              >
                <b-form-checkbox
                  v-model="agent.checkbank3"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  value="1"
                  unchecked-value="0"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group>
                <v-select
                  v-model="agent.dip_bank3_code"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="banklist"
                  :reduce="name => name.bankcode"
                />
              </b-form-group>
              <b-form-group
                label="เลขที่บัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label1"
                  v-model="agent.dip_bank3_accno"
                  placeholder="เลขที่บัญชี"
                />
              </b-form-group>

              <b-form-group
                label="ชื่อบัญชี"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.dip_bank3_accname"
                  placeholder="ชื่อบัญชี"
                />
              </b-form-group>
              <b-form-group
                label="Prefix"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.prefix"
                  placeholder="Prefix"
                />
              </b-form-group>
              <b-form-group
                label="เว็บไซต์"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.site"
                  placeholder="เว็บไซต์"
                />
              </b-form-group>
              <b-form-group
                label="ชื่อเว็บไซต์"
                label-for="customDelimiter"
              >
                <b-form-input
                  id="floating-label2"
                  v-model="agent.site_name"
                  placeholder="ชื่อเว็บไซต์"
                />
              </b-form-group>
              <hr>
              <div class="card-credit">
                <h4>
                  โยกเงินอัตโนมัติ SCB 1
                </h4>
                <b-form-group
                  label="เปิด / ปิด โยกอัตโนมัติ"
                  label-for="customDelimiter"
                >
                  <b-form-checkbox
                    v-model="movemoney.move_dep1_status"
                    class="custom-control-success"
                    name="check-button"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="จำนวนเงินที่ถอน"
                  label-for="vi-first-name"
                >
                  <b-form-input
                    id="vi-first-name"
                    v-model="movemoney.move_dep1_amount"
                    placeholder="0"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="web">ถอนไปยังธนาคาร</label>
                  <v-select
                    v-model="movemoney.move_dep1_bank"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                    class="bg-white rounded"
                    placeholder="เลือกธนาคาร"
                  />
                </b-form-group>
                <b-form-group
                  label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                  label-for="vi-first-acc"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="movemoney.move_dep1_acc"
                      placeholder="ระบุเลขที่บัญชี"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="checkAcc(1)"
                      >
                        ค้นหา
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="px-4 btn-icon"
                  @click="confirm(1)"
                >
                  ยืนยันข้อมูล
                </b-button>
              </div>
            </b-tab>
            <b-tab title="KBANK (กสิกรไทย)">
              <div>
                <b-form-group
                  label="เปิด / ปิด บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-checkbox
                    v-model="agent.checkbank2"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <v-select
                    v-model="agent.dip_bank2_code"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist2"
                    :reduce="name => name.bankcode"
                  />
                </b-form-group>
                <b-form-group
                  label="เลขที่บัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label1"
                    v-model="agent.dip_bank2_accno"
                    placeholder="เลขที่บัญชี"
                  />
                </b-form-group>

                <b-form-group
                  label="ชื่อบัญชี"
                  label-for="customDelimiter"
                >
                  <b-form-input
                    id="floating-label2"
                    v-model="agent.dip_bank2_accname"
                    placeholder="ชื่อบัญชี"
                  />
                </b-form-group>
              </div>
              <hr>
              <div class="card-credit">
                <h4>
                  โยกเงินอัตโนมัติ KBANK
                </h4>
                <b-form-group
                  label="เปิด / ปิด โยกอัตโนมัติ"
                  label-for="customDelimiter"
                >
                  <b-form-checkbox
                    v-model="movemoney.move_dep2_status"
                    class="custom-control-success"
                    name="check-button"
                    :value="1"
                    :unchecked-value="0"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="จำนวนเงินที่ถอน"
                  label-for="vi-first-name"
                >
                  <b-form-input
                    id="vi-first-name"
                    v-model="movemoney.move_dep2_amount"
                    placeholder="0"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="web">ถอนไปยังธนาคาร</label>
                  <v-select
                    v-model="movemoney.move_dep2_bank"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="banklist"
                    :reduce="name => name.bankcode"
                    class="bg-white rounded"
                    placeholder="เลือกธนาคาร"
                  />
                </b-form-group>
                <b-form-group
                  label="ถอนไปยังเลขที่บัญชี (ไม่ต้องใส่ -)"
                  label-for="vi-first-acc"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="movemoney.move_dep2_acc"
                      placeholder="ระบุเลขที่บัญชี"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="primary"
                        @click="checkAcc(2)"
                      >
                        ค้นหา
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="px-4 btn-icon"
                  @click="confirm(2)"
                >
                  ยืนยันข้อมูล
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col md="5">
        <div class="row">
          <div class="col-md-6">
            <div class="g-status success">
              <i
                class="fas fa-wallet fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด หน้าฝาก
              </h6>
              <b-form-checkbox
                v-model="agent.status_dip"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status error">
              <i
                class="fas fa-hands-usd fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด หน้าถอน
              </h6>
              <b-form-checkbox
                v-model="agent.status_wit"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status info">
              <i
                class="fas fa-sack-dollar fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด เติมเครดิตออโต้
              </h6>
              <b-form-checkbox
                v-model="agent.status_auto_dip"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status warning">
              <i
                class="fas fa-ban fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด ตัดเครดิตออโต้
              </h6>
              <b-form-checkbox
                v-model="agent.status_auto_wit"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status warning">
              <i
                class="fas fa-ban fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด SMS เติมเครดิตออโต้
              </h6>
              <b-form-checkbox
                v-model="agent.dep_sms"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status warning">
              <i
                class="fas fa-ban fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด SCB Connect
              </h6>
              <b-form-checkbox
                v-model="agent.dep_connect"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status secondary">
              <i
                class="fas fa-power-off fa-2x mb-1"
                style="color:#fff"
              />

              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด Maintenance
              </h6>
              <b-form-checkbox
                v-model="agent.maintenance"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="col-md-6">
            <div class="g-status info">
              <i
                class="fas fa-alarm-exclamation fa-2x mb-1"
                style="color:#fff"
              />
              <h6
                class="text-white"
                style="font-weight: 700"
              >
                ปิด-เปิด เสียงแจ้งเตือน
              </h6>
              <b-form-checkbox
                v-model="agent.alarm"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-col>

      <b-col
        v-if="userData.agent_id == 7"
        md="6"
      >
        <b-card>
          <h3><i class="fad fa-gifts" /> บัญชีถอนออโต้</h3>
          <b-form-group
            label="เลือกบัญชีถอนออโต้"
            label-for="customDelimiter"
          >
            <v-select
              v-model="agent.auto_withscb_use"
              label="name"
              :options="autolist"
              :reduce="name => name.id"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-gifts" /> จำนวนเทินที่ลูกค้าไม่รับโปร</h3>
          <b-form-group
            label="จำนวนเทิน"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.turn_nopro"
              type="number"
              placeholder="จำนวนเทิน"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3>
            <i class="fad fa-donate" /> ยอดถอนเครดิตสูงสุด
            <small>(โดยไม่ต้อง approve)</small>
          </h3>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_approve"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fab fa-ethereum" /> ยอดถอนต่อครั้ง</h3>
          <b-form-group
            label="ถอนเครดิตต่ำสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_min"
              type="number"
              placeholder="ถอนเครดิตต่ำสุด"
            />
          </b-form-group>
          <b-form-group
            label="ถอนเครดิตสูงสุด"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.withdraw_max"
              type="number"
              placeholder="ถอนเครดิตสูงสุด"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการ truemoney ขาเข้า</h3>
          <b-form-group
            label="เบอร์ทรูมันนี่"
            label-for="customDelimiter"
          >
            <cleave
              id="customDelimiter"
              v-model="agent.truemoney_acc"
              class="form-control"
              :raw="false"
              :options="options.customDelimiter"
              placeholder=""
            />
          </b-form-group>
          <b-form-group
            label="ชื่อบัญชี"
            label-for="customDelimiter"
          >
            <b-form-input
              id="floating-label2"
              v-model="agent.truemoney_name"
              placeholder="ชื่อบัญชี"
            />
          </b-form-group>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการ Line</h3>
          <b-form-group
            label="ไลน์ Contact หน้าเว็บ wallet"
            label-for="customDelimiter"
          >
            <b-form-input
              id="linecontact"
              v-model="agent.linecontact"
              placeholder="ไลน์ Contact หน้าเว็บ wallet"
            />
          </b-form-group>
          <b-form-group
            label="ไลน์ Token ฝาก"
            label-for="linetoken_dip"
          >
            <b-form-input
              id="linetoken_dip"
              v-model="agent.linetoken_dip"
              placeholder="ไลน์ Token ฝาก"
            />
          </b-form-group>
          <b-form-group
            label="ไลน์ Token ถอน"
            label-for="linetoken_wit"
          >
            <b-form-input
              id="linetoken_wit"
              v-model="agent.linetoken_wit"
              placeholder="ไลน์ Token ฝาก"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-wallet" /> จัดการข้อมูลส่ง SMS (THSMS)</h3>
          <b-form-group
            label="ยูสเซอร์เนม"
            label-for="customDelimiter"
          >
            <b-form-input
              id="linecontact"
              v-model="agent.thssms_user"
              placeholder="ยูสเซอร์เนม"
            />
          </b-form-group>
          <b-form-group
            label="พาสเวิร์ด"
            label-for="linetoken_dip"
          >
            <b-form-input
              id="linetoken_dip"
              v-model="agent.thssms_pass"
              placeholder="พาสเวิร์ด"
            />
          </b-form-group>
          <b-form-group
            label="API-KEY"
            label-for="linetoken_wit"
          >
            <b-form-input
              id="thssms_apikey"
              v-model="agent.thssms_apikey"
              placeholder="API-KEY"
            />
          </b-form-group>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card>
          <h3><i class="fad fa-megaphone" /> ข้อความประกาศ</h3>
          <b-form-group
            label="เพิ่มข้อความประกาศ"
            label-for="anounce"
          >
            <b-form-textarea
              id="anounce"
              v-model="agent.announce"
              rows="5"
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      ref="money-rocker"
      hide-footer
      hide-header
      title="โยกเงินอัตโนมัติ"
      centered
    >
      <div class="text-center">
        <img
          src="confirm.png"
          alt="confirm"
          height="80"
        >
        <h5>{{ ShowCheckBank.bankName }}</h5>
        <h4>{{ ShowCheckBank.acc }}</h4>
        <h5>{{ ShowCheckBank.name }}</h5>

        <b-button
          variant="gradient-primary"
          size="sm"
          pill
          @click="hide()"
        >
          ปิดหน้าต่าง
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTab,
  BFormCheckbox,
  BTabs,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      banklist: [],
      banklist2: [{ bankcode: '004', name: 'ธนาคารกสิกรไทย' }],
      agent: {},
      selected: { title: '' },
      option: [{ title: 'Square' }],
      options: {
        customDelimiter: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
      movemoney: {
        prefix: '',
        move_dep1_status: 0,
        move_dep1_amount: 0,
        move_dep1_bank: '',
        move_dep1_acc: '',
        move_dep2_status: 0,
        move_dep2_amount: 0,
        move_dep2_bank: '',
        move_dep2_acc: '',
        move_dep3_status: 0,
        move_dep3_amount: 0,
        move_dep3_bank: '',
        move_dep3_acc: '',
      },
      ShowCheckBank: {
        bankName: '',
        acc: '',
        name: '',
      },
    }
  },
  mounted() {
    this.Get_bank()
    this.getAgent()
    this.getAutolist()
    this.getMovemoney()
  },
  methods: {
    hide() {
      this.$refs['money-rocker'].hide()
    },
    getAutolist() {
      this.$http
        .get('/agent/autowithscblist')
        .then(response => {
          this.autolist = response.data
        })
        .catch(error => console.log(error))
    },
    Get_bank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.banklist = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getAgent() {
      this.$http
        .get('/agent/show')
        .then(response => {
          this.agent = response.data
          this.switch(response.data)
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    getMovemoney() {
      this.show = true
      this.$http
        .get('/agent/show/auto')
        .then(response => {
          this.movemoney = response.data
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async confirm(banknumber) {
      try {
        this.show = true
        this.movemoney.bank_number = banknumber
        const { data: res } = await this.$http.post('/agent/update/movemoney', this.movemoney)
        console.log(res.status)
        if (res.status) {
          this.show = false
          this.Success('บันทึกข้อมูลสำเร็จ')
        } else {
          this.show = false
          this.SwalError('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        this.show = false
        this.SwalError('เกิดข้อผิดพลาด')
      }
    },
    async checkAcc(banknumber) {
      this.show = true
      if (banknumber === 1) {
        if (!this.agent.dip_bank_accno) {
          this.show = false
          this.$swal('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลบัญชี', 'error')
          return
        }
        const Facc = this.agent.dip_bank_accno.replace(/-/g, '')
        const Tacc = this.movemoney.move_dep1_acc.replace(/-/g, '')
        const Tcode = this.movemoney.move_dep1_bank
        const amount = '1.00'
        const { prefix } = this.movemoney
        const banktype = 'dip1'
        await this.CheckAccNameScb(Facc, Tacc, Tcode, amount, prefix, banktype)
      } else if (banknumber === 2) {
        const Tacc = this.movemoney.move_dep2_acc.replace(/-/g, '')
        const Tcode = this.movemoney.move_dep2_bank
        const amount = '1.00'
        const { prefix } = this.movemoney
        const banktype = 'deposit'
        await this.CheckAccNameKbank(Tcode, Tacc, amount, prefix, banktype)
      } else if (banknumber === 3) {
        if (!this.agent.dip_bank3_accno) {
          this.show = false
          this.$swal('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลบัญชี', 'error')
          return
        }
        const Facc = this.agent.dip_bank3_accno.replace(/-/g, '')
        const Tacc = this.movemoney.move_dep3_acc.replace(/-/g, '')
        const Tcode = this.movemoney.move_dep3_bank
        const amount = '1.00'
        const { prefix } = this.movemoney
        const banktype = 'dip3'
        await this.CheckAccNameScb(Facc, Tacc, Tcode, amount, prefix, banktype)
      }
    },
    async CheckAccNameScb(Frombankcode, accno, Tobankcode, amount, prefix, banktype) {
      const obj = {
        facc: Frombankcode,
        tacc: accno,
        tcode: Tobankcode,
        amount,
        prefix,
        banktype,
      }
      this.$http
        .post('movemoney/CheckAccNameScb', obj)
        .then(response => {
          this.show = false
          if (response.data.status) {
            this.$refs['money-rocker'].show()
            const Bank = response.data.data
            this.ShowCheckBank.acc = Bank.accountTo
            this.ShowCheckBank.name = Bank.accountToDisplayName
            this.ShowCheckBank.bankName = this.banklist.find(bank => bank.bankcode === Tobankcode).name
          } else {
            this.$swal('เกิดข้อผิดพลาด', response.data.msg, 'error')
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    async CheckAccNameKbank(bankcode, accno, amount, prefix, type) {
      const obj = {
        toBankCode: bankcode,
        toAccount: accno,
        amount,
        prefix,
        type,
      }
      this.$http
        .post('movemoney/CheckAccNameKbank', obj)
        .then(response => {
          this.show = false
          console.log(response.data)
          if (response.data.status) {
            this.$refs['money-rocker'].show()
            const Bank = response.data.data
            this.ShowCheckBank.acc = Bank.toAccountNo
            this.ShowCheckBank.name = Bank.toAccountName
            this.ShowCheckBank.bankName = Bank.bankName
          } else {
            this.$swal('เกิดข้อผิดพลาด', response.data.msg, 'error')
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    switch(data) {
      if (data.status_dip === '1') {
        this.agent.status_dip = true
      }
      if (data.status_wit === '1') {
        this.agent.status_wit = true
      }
      if (data.status_auto_dip === '1') {
        this.agent.status_auto_dip = true
      }
      if (data.status_auto_wit === '1') {
        this.agent.status_auto_wit = true
      }
      if (data.dep_sms === '1' || data.dep_sms === 1) {
        this.agent.dep_sms = true
      }
      if (data.dep_connect === '1' || data.dep_connect === 1) {
        this.agent.dep_connect = true
      }
      if (data.maintenance === '1') {
        this.agent.maintenance = true
      }
      if (data.alarm === 1) {
        this.agent.alarm = true
      }
    },
    submit() {
      if (this.agent.id) {
        if (this.agent.alarm === true) {
          this.agent.alarm = 1
        }
        this.$http
          .post('/agent/update', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('แก้ไขข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('/agent/store', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('เพิ่มข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.card-credit {
  background: #e9e9e9;
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.g-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
  border-radius: 0.25rem;
  padding: 2.35rem;
  margin-bottom: 1rem;
}
.g-status.success {
  background: linear-gradient(to right, #3dc92a, #00770a);
}
.g-status.error {
  background: linear-gradient(to right, #ff5b5b, #c70000);
}
.g-status.warning {
  background: linear-gradient(to right, #ffb800, #ff8a00);
}
.g-status.info {
  background: linear-gradient(to right, #00b0ff, #0078ff);
}
.g-status.secondary {
  background: linear-gradient(to right, #797979, #424547);
}

i {
  color: #2e5d92;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
